import { Link } from '~/elements/Link/Link'
import { Icon } from '~/elements/Icons/Icon'
import clsx from 'clsx'

type LogoProps = { className?: string; width?: number; height?: number; lightTheme?: boolean }

export const Logo = ({ className = '', width = 100, height = 25, lightTheme }: LogoProps) => {
  const logoVariant = lightTheme ? 'text-sg-white' : 'text-sg-black'

  return (
    <Link
      href="/"
      className={clsx('inline-block', logoVariant, className)}
      aria-label="Return to homepage"
      title="Return to homepage"
    >
      <Icon name="logo-icon" className="h-[25px] w-[100px]" />
    </Link>
  )
}
